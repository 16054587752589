import React, { FC, useMemo, useState } from 'react';
import { signInAnonymously } from 'firebase/auth';
import { auth } from './firebase';
import { AuthProvider } from './AuthContext';
import { Chat } from './Chat';
import { Conversation } from './types/Conversation';
import { getConversationForPropertyId } from './utilities/Conversations';

// eslint-disable-next-line no-console,@typescript-eslint/no-explicit-any
const log = process.env.NODE_ENV !== 'production' ? console.log : (..._params: any[]) => {};

export const App: FC = () => {
    const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
    const [originURL, setOriginURL] = useState<string>('');
    // using memo to save having to use a callback in an effect to perform the async operation
    // noinspection JSIgnoredPromiseFromCall
    useMemo(async () => {
        try {
            await signInAnonymously(auth).then(async () => {
                log('Signing in Complete: ', auth.currentUser);
                const {
                    propertyId = '',
                    userName: name = '',
                    userEmail: email = '',
                    originURL = '',
                } = Object.fromEntries(new URLSearchParams(window.location.search).entries());
                setOriginURL(originURL);
                if (propertyId.length > 0 && auth.currentUser != null) {
                    const conversation = await getConversationForPropertyId(propertyId, auth.currentUser.uid, { name, email });
                    setSelectedConversation(conversation);
                }
            });
        } catch (error) {
            log('Error signing in: ', error);
        }
    }, []);

    return (
        <AuthProvider>
            {selectedConversation == null && (
                <div className="app-loader">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="40" fill="none" viewBox="0 0 94 154">
                        <path
                            fill="#919191"
                            fillRule="evenodd"
                            d="M0 60c0-25.957 21.043-47 47-47s47 21.043 47 47v58H46l-35.5 35.5 8.267-35.5H0V60Zm35-10h14v39H35V50Zm27 0h14v39H62V50Z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                    Loading...
                </div>
            )}
            {selectedConversation != null && (
                <div style={{ height: '100%' }}>
                    <Chat selectedConversation={selectedConversation} setSelectedConversation={setSelectedConversation} originURL={originURL} />
                </div>
            )}
        </AuthProvider>
    );
};
