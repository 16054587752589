import React, { FC } from 'react';

type Props = { address: string; price: string; propertyImage: string; agentLogo: string; agentName: string };
export const PropertyCard: FC<Props> = ({ propertyImage, address, price, agentLogo, agentName }) => (
    <div className="property-card">
        <img src={propertyImage} alt="property" />
        <div className="property-details">
            <div>{address}</div>
            <div>{price}</div>
        </div>
        <div className="logo">
            <img src={agentLogo} alt={agentName} />
        </div>
    </div>
);
