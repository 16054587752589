import React, { ChangeEvent, FC, KeyboardEvent, MouseEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { addDoc, collection, doc, onSnapshot, orderBy, query, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from './firebase';
import { AuthContext } from './AuthContext';
import { Conversation } from './types/Conversation';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Message } from './types/Message';
import { PropertyDetails } from './types/PropertyDetails';
import { Working } from './Working';
import { Feedback } from './Feedback';
import { PropertyCard } from './PropertyCard';

type Props = {
    selectedConversation: Conversation | null;
    setSelectedConversation: (conversation: Conversation | null) => void;
    originURL: string;
};

export const Chat: FC<Props> = ({ selectedConversation, originURL }) => {
    const authContext = useContext(AuthContext);
    const [isTyping, setIsTyping] = useState(true);
    const [conversationID, setConversationID] = useState<string>('');
    if (!authContext) {
        // handle the case where the context is not inside a Provider
        throw new Error('useContext must be used within a Provider');
    }
    const { currentUser } = authContext;
    const [messages, setMessages] = useState<Message[]>([]);
    const [newMessage, setNewMessage] = useState<string>('');
    const [propertyDetails, setPropertyDetails] = useState<PropertyDetails>();
    const [messageLimitReached, setMessageLimitReached] = useState<boolean>(false);
    const [feedbackState, setFeedbackState] = useState<{ rating?: string; comment?: string }>(
        selectedConversation?.feedback || { rating: '', comment: '' }
    );

    const messageInput = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (selectedConversation != null) {
            setConversationID(selectedConversation.id);
            const messagesRef = collection(db, `conversations/${selectedConversation.id}/messages`);

            if (selectedConversation.propertyDetails) {
                setPropertyDetails(selectedConversation.propertyDetails);
            }

            const q = query(messagesRef, orderBy('timestamp'));

            // unsubscribe
            return onSnapshot(q, (querySnapshot) => {
                const loadedMessages = querySnapshot.docs.map((doc) => {
                    const formattedMessage = { docId: doc.id, ...doc.data() };
                    return formattedMessage as Message;
                });
                setMessages(loadedMessages);
                if (loadedMessages.length > 0 && loadedMessages[loadedMessages.length - 1].uid !== currentUser?.uid) {
                    setIsTyping(false);
                    if (loadedMessages.length >= 200) {
                        setMessageLimitReached(true);
                    }
                }

                const lastMessage = loadedMessages[loadedMessages.length - 1];
                if (lastMessage?.event && !lastMessage?.eventProcessed) {
                    // eslint-disable-next-line no-restricted-globals
                    parent.postMessage({ isGAEvent: true, eventType: lastMessage.event }, originURL);

                    const currentMessageRef = doc(db, `conversations/${selectedConversation.id}/messages`, lastMessage.docId);
                    updateDoc(currentMessageRef, { eventProcessed: true });
                }
            });
        }
    }, [currentUser?.uid, selectedConversation, originURL]);

    const startOfNewResponseMessage = useRef<HTMLDivElement>(null);
    const endOfNewUserMessage = useRef<HTMLDivElement>(null);

    useEffect(() => {
        startOfNewResponseMessage.current?.scrollIntoView({ behavior: 'smooth' });
        endOfNewUserMessage.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setNewMessage(event.target.value);
    };

    const handleSend = useCallback(async (): Promise<void> => {
        if (newMessage.length > 0 && selectedConversation != null && currentUser != null && !messageLimitReached) {
            await addDoc(collection(db, `conversations/${selectedConversation.id}/messages`), {
                text: newMessage,
                timestamp: serverTimestamp(),
                uid: currentUser.uid,
            });
            setNewMessage('');
            setIsTyping(true);
        }
    }, [currentUser, newMessage, selectedConversation, messageLimitReached]);

    const handleSendClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();
            messageInput.current?.focus();
            // eslint-disable-next-line no-console
            handleSend().catch(console.error);
        },
        [handleSend]
    );

    const handleKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSend().catch((err) => {
                // eslint-disable-next-line no-console
                console.error('Error sending message', err);
            });
        }
    };

    const messageDate = (message: Message): string => {
        if (message.timestamp?.seconds === undefined) return '';
        const date = new Date(message.timestamp.seconds * 1000);
        if (date.toLocaleDateString('en-GB') === new Date().toLocaleDateString('en-GB')) {
            return date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric' });
        }
        return date.toLocaleDateString('en-GB', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' });
    };

    const currencyFormatter = new Intl.NumberFormat('en-gb', {
        style: 'currency',
        currency: propertyDetails?.currency === 'GBP' ? 'GBP' : 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const [feedbackShown, setFeedbackShown] = useState<boolean>(false);
    const handleFeedback = (feedback: { rating: string; comment?: string }) => {
        const currentConversationRef = doc(db, 'conversations/', conversationID);
        const newFeedbackState = { ...feedbackState, ...feedback };
        updateDoc(currentConversationRef, { feedback: newFeedbackState });
        setFeedbackState(newFeedbackState);
    };
    const hideFeedback = () => {
        setFeedbackShown(false);
    };
    const showFeedback = () => {
        setFeedbackShown(true);
    };

    const containerClass = feedbackShown ? 'chat +feedback' : 'chat';

    const feedbackMessage = feedbackState.rating || feedbackState.comment ? 'Thanks for your feedback!' : 'Help us improve Liv!';

    return (
        <div className={containerClass}>
            <PropertyCard
                address={propertyDetails?.addressPart1 || ''}
                price={propertyDetails?.price ? currencyFormatter.format(propertyDetails.price) : 'Price Not Provided'}
                propertyImage={propertyDetails?.imageUrl || ''}
                agentLogo={propertyDetails?.agents[0].logo.sizes.find(({ width }) => width === '320')?.url || ''}
                agentName={propertyDetails?.agents[0].name || ''}
            />
            <div className="messages">
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`message-item ${message.uid === currentUser?.uid ? 'sent' : 'rcvd'}`}
                        ref={index === messages.length - 1 && message.uid !== currentUser?.uid ? startOfNewResponseMessage : null}
                    >
                        <div className="sender"></div>
                        <div data-time={messageDate(message)} className="msg">
                            <ReactMarkdown linkTarget="_blank">{message.text}</ReactMarkdown>
                        </div>
                    </div>
                ))}
                {isTyping && (
                    <div ref={endOfNewUserMessage}>
                        <Working />
                    </div>
                )}
                {messageLimitReached && (
                    <div className="message-limit-error">Sorry, you have reached the maximum number of messages allowed on one property</div>
                )}
            </div>
            <Feedback handler={handleFeedback} hide={hideFeedback} />
            <div className="feedback-cta" onClick={showFeedback}>
                {feedbackMessage}
            </div>
            <div className="new-message">
                <input
                    type="text"
                    placeholder="Ask me a question..."
                    disabled={messageLimitReached}
                    ref={messageInput}
                    autoFocus
                    value={newMessage}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    className="tk-degular"
                />
                <button onClick={handleSendClick} className={'send-button' + (messageLimitReached ? ' disabled' : '')}>
                    {' '}
                    Send{' '}
                </button>
            </div>
        </div>
    );
};
