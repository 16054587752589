import { addDoc, collection, getDocs, limit, query, serverTimestamp, where } from 'firebase/firestore';
import { db } from '../firebase';
import { Conversation } from '../types/Conversation';
import axios from 'axios';
import { UserDetails } from '../types/UserDetails';
import { Agent } from '../types/Agent';

export const getConversationForPropertyId = async (propertyId: string, uid: string, userDetails: UserDetails): Promise<Conversation | null> => {
    if (propertyId === undefined || propertyId === null || propertyId === '') {
        return null;
    }
    // Check if this user has a conversation for this property
    const q = query(collection(db, 'conversations'), where('uid', '==', uid), where('propertyId', '==', propertyId), limit(1));
    const querySnapshot = await getDocs(q);

    type PropertyResponse = {
        address: { line1: string; town: string; postcode: string };
        price: number;
        currency: string;
        leadImage: { sizes: { url: string }[] };
        agents: Agent[];
    };
    const makeResponse = (id: string, data: PropertyResponse) => ({
        id,
        title: data.address.line1 ?? 'no address',
        propertyDetails: {
            addressPart1: data.address.line1,
            addressPart2: data.address.town + ', ' + data.address.postcode,
            price: data.price,
            currency: data.currency,
            imageUrl: data.leadImage?.sizes[0].url || 'https://site-static.propertynews.com/images/2209/loading-graphic.svg',
            agents: data.agents,
        },
        userDetails: userDetails,
    });

    if (!querySnapshot.empty) {
        // Query returned a document, so extract and return it
        const doc = querySnapshot.docs[0];
        return makeResponse(doc.id, doc.data().propertyDetails);
    } else {
        try {
            const response = await axios.get('https://beta-api.propertynews.com/v3/property/' + propertyId + '?fromChat=true');
            const doc = await addDoc(collection(db, 'conversations'), {
                title: response.data.address.line1 ?? 'no address',
                uid,
                propertyId,
                agentName: response.data.agents[0].name,
                propertyDetails: response.data,
                userDetails: userDetails,
                updatedAt: serverTimestamp(),
            });
            return makeResponse(doc.id, response.data);
        } catch (error) {
            // TODO: handle error
            // eslint-disable-next-line no-console
            console.error(error);
            return null;
        }
    }
};
