import React from 'react';

export const Working = () => (
    <div className="message-item rcvd">
        <div className="sender"></div>
        <div>
            <div className="working">
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
            </div>
        </div>
    </div>
);
